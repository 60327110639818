/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Cookie Script
// const scriptElement = document.createElement("script")
// scriptElement.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
// scriptElement.type = "text/javascript"
// scriptElement.setAttribute("charset", "UTF-8")
// // Stage
// scriptElement.setAttribute(
//   "data-domain-script",
//   "bf951c7b-785d-4bea-ab35-7816b763015b-test"
// )
// Production
// scriptElement.setAttribute(
//   "data-domain-script",
//   "bf951c7b-785d-4bea-ab35-7816b763015b"
// )

// Cookie Button
// const cookieButton = document.createElement("button")
// cookieButton.id = "ot-sdk-btn"
// cookieButton.className = "ot-sdk-show-settings"
// cookieButton.innerText = "Cookie Settings"
import "bootstrap/dist/js/bootstrap.js";

export const onClientEntry = () => {
  window.onload = () => {
    // Cookie Script
    // document.head.appendChild(scriptElement)
    // Cookie Button
    // document.body.appendChild(cookieButton)
  }
}
